@import 'src/styles/font-stack';
@import 'src/styles/media';

// @todo Find a way to derive from the color tokens - this is a bit of a hack
$dark-blue-005: rgba(0, 0, 51, 0.05);
$stone-grey-010: rgba(245, 245, 239, 0.1);
$black-04: rgba(0, 0, 0, 0.4);

.card {
  position: relative;
  z-index: var(--layer-ground);
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-radius: 1rem;
  top: 0;
  bottom: 0;
  width: 18.75rem;
  overflow: hidden;
  height: 100%;
  min-height: 500px;

  &.fat {
    min-height: 475px;
    width: 22rem;

    &.none {
      .content {
        margin-top: 2rem;
      }
    }

    .content {
      h2 {
        @include font-title-32;
      }

      h3 {
        @include font-title-20;
      }

      p {
        @include font-regular-16;
      }
    }
    .action {
      @include font-button-20;
    }
  }

  &:hover {
    .action {
      background-color: $dark-blue-005;
      color: var(--color-dark-blue-700);

      &.dark-blue {
        background-color: $stone-grey-010;
      }
    }

    .symbol {
      opacity: 0.03;

      &.dark-blue {
        opacity: 0.1;
      }
    }
  }

  .symbol {
    fill: var(--color-dark-blue);
    opacity: 0.05;
    width: 100%;
    height: auto;
    transition: 0.2s ease-in;
    position: absolute;
    transform: scale(1.52);

    &.dark-blue {
      opacity: 0.12;
      fill: var(--color-stone-grey-100);
    }
  }

  .image-container {
    position: relative;
    width: 100%;
    height: 11.875rem;
    overflow: hidden;
    border-radius: 1rem;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0 0.5rem;
    margin: 1rem 0;
    position: relative;
    color: var(--color-dark-blue);

    &.dark-blue {
      color: var(--color-white);
    }

    h2 {
      @include font-title-20;

      margin-bottom: 0.125rem;
    }

    h3 {
      @include font-regular-12;

      margin-bottom: 0;
      opacity: 0.5;
    }

    p {
      @include font-regular-14;

      margin-bottom: 0;
    }
  }

  &.none {
    .content {
      height: 100%;
      margin: 3rem 0 1rem;
    }
  }

  &.stretch {
    padding: 1rem;

    .image-container {
      height: 100%;
      transition: 0.15s ease-in-out;
    }

    .content {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      padding: 0;
      margin: 0;
      justify-content: center;
      align-items: center;
      color: var(--color-white);

      h2 {
        @include font-title-24;

        margin-bottom: 0;
        padding: 1.5rem;
        text-align: center;
      }
    }

    &:hover {
      .image-container {
        background-color: $black-04;
      }
    }
  }

  &.bottom {
    .image-container {
      height: 100%;
      margin-top: auto;
    }

    .content {
      height: initial;
      margin-top: 0.5rem 0 1rem;
      transition: 0.2s ease-in;

      h2 {
        @include font-title-24;

        margin-bottom: 0;
      }
    }

    &:hover {
      .content {
        margin-bottom: 1.5rem;
      }
    }
  }

  .action {
    @include font-button-14;

    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    width: 100%;
    border: 0.0938rem solid var(--color-dark-blue);
    padding: 1rem 1.25rem;
    border-radius: 0.75rem;
    transition: 0.2s ease-in;
    color: var(--color-dark-blue);

    &.dark-blue {
      color: var(--color-white);
      border: 0.0938rem solid var(--color-white);
    }
  }

  .bulletList {
    @include font-button-16;

    position: relative;
    display: flex;
    align-items: center;
    margin-top: auto;
    width: 100%;
    color: var(--color-dark-blue);

    &.dark-blue {
      color: var(--color-white);
    }

    span {
      margin-right: 1rem;
    }
  }
}
