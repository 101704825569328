@import 'src/styles/media.scss';
@import 'src/styles/font-stack';

$calculate-min-height: 740px;

.calculate-section {
  max-width: var(--max-size-sm);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @include media(md) {
    margin: 0 auto;
    flex-direction: row;
    gap: 4rem;

    > * {
      width: 55%;
    }
  }

  &.inverted {
    color: var(--color-text-inverted);
  }
}

.calculate-section-no-image {
  max-width: var(--max-size-sm);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @include media(md) {
    margin: 0 auto;
    flex-direction: row;
    gap: 4rem;

    > * {
      width: 100%;
    }
  }

  &.inverted {
    color: var(--color-text-inverted);
  }
}

.heading {
  @include font-display-50;
  margin-bottom: 1rem;
  font-size: 2.5rem;
}

.description {
  @include font-regular-18;
  margin-bottom: 2rem;
}

.submit-description {
  @include font-regular-12;
  text-align: center;
  margin-top: 1.5rem;
  display: block;
  padding: 0 1rem;
}
.front-page {
  color: var(--color-dark-blue-500);
}

.calculator-container {
  margin: 0 auto;
  @include media(md) {
    margin: 0;
  }
}

.image-container {
  height: $calculate-min-height;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 9999;

  @include media(md) {
    border-radius: 2rem;
    min-height: unset;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;

    h3 {
      @include font-title-20;
      margin: 0;
    }

    h2 {
      @include font-display-70;
      line-height: 4.8125rem;
      margin: 0;
    }

    .logo {
      path {
        fill: var(--color-white);
      }
    }
  }
}

.distance {
  margin-bottom: 1.5rem;

  @include media(md) {
    margin-bottom: 2rem;
  }
}

.terms-container {
  // @todo handle this in the checkbox component
  [class*='checkbox'] {
    @include font-regular-12;

    .inverted & {
      border-radius: 1rem;
      background-color: var(--color-stone-grey-900);
      padding: 1rem;
    }

    span {
      @include font-regular-12;
    }
  }
  .link {
    &:hover {
      text-decoration: underline;
    }
  }
}

.success-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-height: 850px;
  padding: 0 4rem;
}

.message h2 {
  font-size: 2rem;
}
.message p {
  font-size: 1.5rem;
}
