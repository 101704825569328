@import 'src/styles/font-stack';
@import 'src/styles/media';

.slider {
  li {
    display: flex;
    align-items: center;
  }

  li + li {
    margin-left: 3rem;
  }

  svg {
    max-height: 1.375rem;
    width: auto;
  }
}

.larger {
  svg {
    max-height: 2rem;
  }
}
