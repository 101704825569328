@import 'src/styles/media';
@import 'src/styles/font-stack';

.loading-wrapper {
  color: var(--color-white);
  width: 200px;
  margin: 0 auto;
  text-align: center;
  min-height: 45em;

  video {
    width: 200px;
  }

  @include media(md) {
    min-height: 49em;
    video {
      width: 400px;
    }
  }

  width: 100%;
  max-width: var(--max-size-sm);
  margin: 0 auto;
  box-sizing: border-box;

  & > h2,
  & > p,
  & > h3,
  & .content .buttons {
    padding: 0 var(--horizontal-gutter);
  }

  &.box {
    border-radius: 2rem;
    background: var(--color-dark-blue);
    padding: 2rem 0;

    @include media(md) {
      padding: 4rem 0;

      > *:not(.content),
      .content .buttons {
        padding-right: 6rem;
        padding-left: 6rem;
      }
    }
  }
}
.partnersWrapper {
  max-width: var(--max-size-sm);
  border-radius: 1.5rem;
  margin: 0 auto;
  background: #000033;
  margin-top: 5rem;
  padding: 2rem;
  position: relative;
  z-index: 99;

  @include media(md) {
    padding: 3rem;
    margin-top: 0;
  }
  span {
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    padding-bottom: 1rem;
    display: block;
  }
}
.calcWrapper {
  max-width: var(--max-size-sm);
  border-radius: 1.5rem;
  margin: 0 auto;
  padding: 0 1.5rem;
  background: #000033;

  @include media(md) {
    padding: 4rem;
    margin-top: 0;
    background: rgba(245, 245, 239, 0.1);
  }
}
