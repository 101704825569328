@import 'src/styles/font-stack';
@import 'src/styles/media';
@import 'src/styles/not-supports-gap';

.wrapper {
  position: relative;

  border-radius: var(--border-radius-large) var(--border-radius-large) 0 0;
  color: var(--color-stone-grey);
  width: 100%;
  max-width: var(--max-size-lg);
  margin: 0 auto;
  padding: 0;
  min-height: 47.5rem;
  background: #1b1b4a;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center; /* Alternativ: center, space-around, etc. */
  align-items: stretch; /* Gör att elementen fyller höjden om container har en definierad höjd */

  @include media(md) {
    border-radius: var(--border-radius-large);
    padding: 5rem 2rem;
    margin-top: 0;
    flex-direction: row;
    gap: 5rem;
  }
  .content {
    padding: 2rem;
  }
  .flex_item {
    display: flex; /* Gör flex_item själv till en flexcontainer */
    flex: 1; /* Gör att det tar lika mycket plats som andra flex-items */
    align-items: center; /* Vertikal centrering av innehåll */
    justify-content: center; /* Horisontell centrering av innehåll */
    background-color: #fff;
    max-width: 500px;
    border-radius: 2rem;
    color: #1b1b4a;
    padding: 2rem;
    margin-bottom: 0rem;

    @include media(md) {
      margin-bottom: 6rem;
    }
  }
  .content {
    position: relative;

    @include media(md) {
      position: unset;
      padding: unset;
      padding-top: 2rem;
      max-width: 50%;
    }
    p {
      padding-top: 0rem;
      padding-bottom: 1rem;
      @include media(md) {
        position: unset;
        padding: unset;
        padding-top: 1rem;
      }
    }
  }

  h1 {
    @include font-display-50;
    margin-bottom: 1rem;
    white-space: pre-line;

    @include media(md) {
      @include font-display-70;
      margin-bottom: 0rem;
      white-space: normal;
      max-width: 50rem;

      span {
        color: #19e4ac;
      }
    }

    br {
      display: none;
      @include media(md) {
        display: block;
      }
    }
  }

  p {
    @include font-intro-18;
    margin: 0;
    max-width: 46.5rem;

    @include media(md) {
      @include font-intro-24;
    }
    br {
      display: none;
      @include media(md) {
        display: block;
      }
    }
  }

  .actions {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: row;
    gap: 0.75rem;

    @include not-supports-gap {
      > a + a {
        margin-left: 0.75rem;
      }
    }

    @include media(md) {
      margin-top: 2.5rem;
      gap: 1.375rem;
      max-width: 40rem;
      align-items: stretch;
    }
  }

  .howItWorksButton {
    padding-top: 1.5rem;
    display: inline-block;
    width: 100%;
    @include media(md) {
      width: auto;
      padding-top: 2rem;
    }
  }
}

.modalButton {
  padding: 0rem 4vw;
  border-radius: 0.5rem;
  border-width: 0;

  &.redish {
    background-color: var(--color-redish);
    border-color: var(--color-redish);
  }
}

.content ul {
  margin: 0;
  padding: 0;
  text-align: left;
  padding-top: 0;
  @include media(md) {
    padding-top: 1rem;
  }
}

.content li {
  list-style-position: inside;
  vertical-align: middle;
  list-style-type: none;

  /** the image will be vertically aligned in the center **/
  background: url('/assets/icons/default/check-circle-bold-green.svg') left center no-repeat;

  /** move the text to the right **/
  padding-left: 2rem;
  margin: 0.5rem 0;
  line-height: 1.6;
  letter-spacing: -0.02em;
  @include font-intro-18;
  @include media(md) {
    @include font-intro-24;
  }
}

.trustpilot {
  margin: 2rem 0;
  display: flex;
  gap: 0.8rem;
  justify-content: flex-start;
  align-items: center;
  height: auto;
}
.trustpilot span {
  color: #fff;
  font-size: 1.1rem;
  font-weight: bold;
  padding-top: 2px;
}

.modal_item {
  display: flex;
  align-items: flex-start;
  gap: 10px; /* Ger mellanrum mellan text och ikon */
}

.modal_item .Modal {
  display: flex;
  align-items: flex-start;
}
