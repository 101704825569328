@import 'src/styles/font-stack';
@import 'src/styles/media';
@import 'src/styles/not-supports-gap';

.wrapper {
  position: relative;
  width: 100%;
  padding: 4rem 1.5rem 3.0625rem;
  background-color: var(--color-yellowish);
  overflow: hidden;
  border-radius: var(--border-radius-large);

  @include media(md) {
    padding: 6rem 10.25rem;
    border-radius: 0;
  }

  .symbol {
    fill: var(--color-dark-blue);
    opacity: 0.05;
    position: absolute;
    width: 1088.95px;
    height: 2251.07px;
    top: 50%;
    transform: translate(-50%, -50%) rotate(94.74deg);
  }

  .inner {
    max-width: var(--max-size-sm);
    margin: 0 auto;
    overflow: hidden;

    .headline {
      max-width: var(--max-size-sm);

      font-weight: var(--font-weight-bold);
      font-variation-settings: 'wght' var(--font-weight-bold);
      line-height: 1.2;
      letter-spacing: -0.02em;
      font-size: 4.375rem;
      margin-top: 3.5rem;
      margin-bottom: 1rem;
      max-width: 34.625rem;
      display: block;
    }
    .info {
      margin-bottom: 2rem;

      @include media(md) {
        margin-bottom: 3rem;
      }
    }

    .icon {
      width: auto;
      height: 2.25rem;
    }

    h2 {
      @include font-display-70;
      margin-bottom: 3.0625rem;
      max-width: 18.8125rem;

      @include media(md) {
        margin-top: 3.5rem;
        margin-bottom: 2rem;
        max-width: 34.625rem;
      }
    }

    .image {
      border-radius: 2rem;
    }
  }
  .button {
    max-width: 20rem;
    margin-top: 2rem;
  }
}
.carousel {
  max-width: var(--max-size-sm);
  width: 100%;
  gap: 1rem;

  .slider {
    margin-bottom: 2rem;
    width: 100%;
    li {
      display: flex;
      align-items: flex-start;
    }
  }
}
.icon-touch-wrapper {
  display: flex;
  justify-content: flex-end;
}
