@import 'src/styles/font-stack';
@import 'src/styles/media';

.magazine {
  width: 100%;
  max-width: var(--max-size-sm);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  background-color: var(--color-dark-blue);
  margin: 1.5rem auto;
  overflow: hidden;

  & > h2,
  & > p,
  & .content .buttons {
    padding: 0 var(--horizontal-gutter);
  }

  @include media(lg) {
    gap: 3rem;
    margin: 0 auto;
  }

  a {
    width: 100%;
  }

  .h2 {
    @include font-title-32;

    color: var(--color-stone-grey);

    @include media(md) {
      @include font-display-50;
      margin: 0;
    }
  }

  .carousel {
    width: 100%;
    gap: 1rem;

    .slider {
      li {
        display: flex;
        align-items: center;
      }
    }
  }
}
