@import 'src/styles/font-stack';
@import 'src/styles/media';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 1px solid;
  border-radius: 30px;
  padding: 2rem;

  .stars {
    display: flex;
  }

  .review {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    @include media(md) {
    }

    h3 {
      @include font-title-24;
      color: var(--color-dark-blue);
      margin: 0rem 0 1rem 0;
    }
    h2 {
      margin-bottom: 2rem;
    }

    p {
      @include font-regular-16;
      color: var(--color-dark-blue);
      margin: 0;
      line-height: 1.5rem;
    }

    span {
      @include font-semibold-18;
      color: var(--color-dark-blue-700);
    }
  }
}
