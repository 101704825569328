@import 'src/styles/media.scss';
@import 'src/styles/font-stack';

.car-loan-calculator-container {
  margin-bottom: 1.5rem;
}

.distance {
  margin-bottom: 1.5rem;

  @include media(md) {
    margin-bottom: 2rem;
  }
}

.terms-container {
  // @todo handle this in the checkbox component
  [class*='checkbox'] {
    @include font-regular-12;

    .inverted & {
      border-radius: 1rem;
      background-color: var(--color-stone-grey-900);
      padding: 1rem;
    }

    span {
      @include font-regular-12;
    }
  }
  .link {
    &:hover {
      text-decoration: underline;
    }
  }
}

.errorMessage {
  @include font-regular-14;
  color: var(--color-error);
  line-height: 1.375rem;
  padding-left: 1.5rem;
  padding-top: 1rem;
  display: block;
}
.number-plate-for-text {
  display: block;
  text-align: center;
  font-size: 1.2rem;
  padding-bottom: 1rem;
  font-weight: bold;
}
.number-plate {
  border: 0.125rem solid var(--color-black);
  border-radius: 0.625rem;
  display: flex;
  overflow: hidden;
  gap: 0.625rem;
  background-color: var(--color-white);
  width: 220px;
  margin: 0 auto;

  input[type='text'] {
    border: none;
    outline: none;
    font-family: monospace;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 34px;
    letter-spacing: 0.5ch;
    font-kerning: normal;
    width: 100%;
    padding: 0;
    margin-bottom: 0.5rem;
  }
}

.inputWrapper {
  margin-bottom: 2rem;
}
