@import 'src/styles/font-stack';
@import 'src/styles/media';

.wrapper {
  width: 100%;
  max-width: var(--max-size-sm);
  margin: 0 auto;
  background-color: var(--color-dark-blue);
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 0 var(--horizontal-gutter);
  overflow-y: hidden;

  h2 {
    @include font-display-50;
    margin: 0;
  }

  @include media(md) {
    padding: 6rem var(--horizontal-gutter);
  }

  strong {
    display: block;
  }
}
