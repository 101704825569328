@import 'src/styles/font-stack';
@import 'src/styles/media';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 6rem;

  @include media(md) {
    flex-direction: row;
    gap: 2.275rem;
  }

  .card-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;

    &:nth-of-type(1) {
      .image-container {
        border-radius: 0 1.5rem 1.5rem;
      }
    }

    &:nth-of-type(2) {
      .image-container {
        border-radius: 1.5rem 1.5rem 0;
      }
    }

    &:nth-of-type(3) {
      .image-container {
        border-radius: 1.5rem 1.5rem 1.5rem 0;
      }
    }

    .image-container {
      position: relative;
      width: 100%;
      overflow: hidden;
      z-index: 0;
      aspect-ratio: 341 / 421;

      // Fix for Safari and Chrome prior to iOS 15
      @supports not (aspect-ratio: 1 / 1) {
        padding-bottom: 123.46%;
      }

      @include media(md) {
        flex-direction: row;
      }
    }

    h3 {
      @include font-title-24;
      margin: 0;
    }

    p {
      @include font-regular-16;
      margin: 0;
      max-width: 95%;
      opacity: 0.8;
    }

    ul {
      flex: 1 1 0px;
      padding: 0;

      li {
        path {
          stroke: var(--color-mint-sweet);
        }
      }

      li:first-of-type {
        padding-top: 0;
      }

      li:last-of-type {
        padding-bottom: 0;
      }

      hr {
        display: none;
      }
    }
  }
}
.showBg {
  background: var(--color-sky-blue-300);
  padding: 3rem 1.5rem 3rem 1.5rem;
  color: #000033;

  &:nth-of-type(1) {
    border-radius: 0 1.5rem 1.5rem;
  }

  &:nth-of-type(2) {
    border-radius: 1.5rem 1.5rem 0;
  }

  &:nth-of-type(3) {
    border-radius: 1.5rem 1.5rem 1.5rem 0;
  }

  .icons {
    display: flex;
    justify-content: center;
  }
}
