@import 'src/styles/font-stack';

.wrapper {
  width: 100%;
  max-width: var(--max-size-sm);
  display: flex;
  flex-direction: column;
  gap: 3.75rem;
  padding: 4.25rem 1.5rem;
  color: var(--color-stone-grey);
  margin: 0 auto;
  background-color: var(--color-dark-blue);

  .intro {
    h2 {
      @include font-display-62;
      margin-bottom: 5px;
    }

    p {
      @include font-regular-18;
      margin: 0;
    }
  }
}
